.icon {
  font-size: 14px;
}

div:global(.dropdown-options) {
  div:global(.dropdown-item) {
    span {
      &.projectItem {
        padding-left: 12px !important;
      }
    }
  }
}