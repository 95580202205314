@import '../../../../styles/Variables';

.container {
  margin: 32px 0;
  list-style: none;
  padding: 0;
}

.item {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;

  span:first-child {
    font-weight: 700;
    width: 110px;
  }
}
