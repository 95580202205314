@import '../../../../styles/Variables';

.toggle {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 16px;
  button {
    margin-left: 8px;
  }
}

.clear {
  float: right;
  .inactive {
    font-weight: normal;
  }
}

.projectTitle {
  margin: 0;
}

.mapLayers {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: -20px;
}
