@import '../../../styles/Variables';
.container {
  font-weight: 400;
  font-size: 14px;
  color: $geodocs--button--disabled--color;
  height: 100%;
  width: 100%;
  padding-left: 12px !important;
}

.disabled {
  cursor: not-allowed;
}

.icon {
  font-size: 16px;
}
