@import '../../../../styles/Variables';

.modal {
  width: $geodocs--modal--width;
}

.projectInfo {
  list-style: none;
  margin-top: 32px;
  padding: 0;

  li {
    display: flex;
    margin: 8px 0;
  }
}

.userSelector {
  margin-bottom: 16px;
  span:global(.pill-wrapper.is-colour-1) {
    background-color: $geodocs--project-user-management--appointing-party-user-modal--user-selector--pill-wrapper--background-color;
    color: $geodocs--project-user-management--appointing-party-user-modal--user-selector--pill-wrapper--color;
  }
  div:global(.suggestions-wrapper) {
    max-height: 160px;
    overflow-y: auto;
  }
}

.partyRolesCheckBox {
  div:global(.checkbox) {
    border: 1px solid $geodocs--project-user-management--appointing-party-user-modal--part-role-checkbox--border-color !important;
  }
  div:global(.is-checked) {
    background: $geodocs--project-user-management--appointing-party-user-modal--part-role-checkbox-is-checked--background-color !important;
    color: $geodocs--project-user-management--appointing-party-user-modal--part-role-checkbox-is-checked--color !important;
  }
}
