@import '../../../styles/Variables';

.actionBarLabel {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}

.dropdown {
  width: 200px !important;
}

.marginBetweenFiltersAndSort {
  margin-left: 40px;
}

.marginToSearchButton {
  margin-left: 10px;
}
