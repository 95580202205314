@import '../../../../styles/Variables';
.container {
  display: flex;

  .selectorWrapper {
    flex-grow: 1;
  }

  .buttonWrapper {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
  }
}
