@import '../../../../styles/Variables';

.container {
  font-weight: 400;
  font-size: 14px;
  color: $geodocs--button--disabled--color;
  cursor: not-allowed;
  margin-left: 12px;
  width: 100%;
  height: 100%;
}
