@import '../../../../styles/Variables';

.container {
  font-weight: 400;
  font-size: 14px;
  height: 100%;
}

.icon {
  font-size: 16px;
}

.disableProject {
  color: $geodocs--button--disabled--color;
  cursor: not-allowed;
}

div:global(.dropdown-options) {
  div:global(.dropdown-item) {
    span {
      &.container {
        padding-left: 12px !important;
      }
    }
  }
}