.container {
  font-weight: 400;
  height: 100%;
}

.icon {
  font-size: 14px;
}

.taskTeamTitle {
  font-weight: 700;
}

.selectedProgramme {
  font-size: 14px;
}

.selectedProject {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}

div:global(.dropdown-options) {
  div:global(.dropdown-item) {
    span {
      &.container {
        padding-left: 24px !important;
      }
    }
  }
}
