@import '../../../styles/Variables';

.headerTitle {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.headerIcon {
  margin-right: 28px;
}

.modalContainer {
  max-height: 500px;
  overflow-y: auto;
}

.externalUsers {
  margin-bottom: 16px;
  padding-left: 20px;
}

.countCircle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: $geodocs--transmittal-visible-modal--count-circle--background-color;
  font-size: 14px;
  color: $geodocs--transmittal-visible-modal--count-circle--color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordionPanelTitle {
  display: flex;
  align-items: center;
  font-size: 14px;

  .accordionPanelTitleLabel {
    font-weight: normal;
  }
}
.teamIcon {
  margin-right: 16px;
  font-size: 14px;
}

.userItem {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 8px;

  span.userCircle {
    width: 31px;
  }
  span.displayName {
    margin-left: 8px;
    text-overflow: ellipsis;
  }
}
