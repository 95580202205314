@import '../../../../../styles/Variables';

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 16px;

  button:enabled:hover {
    color: $geodocs--black;
  }
}

.dropdownMenu li {
  text-decoration: none;
  padding: 12px 20px;
  font-size: 14px;
}

.dropdownMenuList li {
  position: relative;
}

.dropdown li:hover {
  background-color: $geodocs--dropdown-list--hover--background-color;
}

.dropdownMenu {
  background: $geodocs--dropdown-list--background-color;
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 100;
  width: 221px;
  box-shadow: 0px 0px 4px rgba(18, 18, 18, 0.25);
  z-index: $geodocs--dropdown-list--z-index;
  > :nth-child(1) {
    border-bottom: 1px solid #dddddd;
    padding: 4px 0;
  }
  > :nth-child(6) {
    border-top: 1px solid #dddddd;
    padding: 4px 0;
  }
}

.dropdownMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownMenuList .dropdownSubmenuList {
  display: none;
  position: absolute;
  background: $geodocs--dropdown-list--background-color;
  width: 221px;
  left: 100%;
  top: -4px;
  box-shadow: 0px 0px 4px rgba(18, 18, 18, 0.25);
  padding: 4px 0;
  transform: translateY(0);
}

.dropdownMenuList {
  & > li:hover {
    & > .dropdownSubmenuList {
      display: block;
    }
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
}

.iconButton {
  margin-right: 0.5rem;
}

.iconRight {
  margin-left: 50px;
  margin-top: 4px;
}
