@import '../../../styles/Variables';

.initiatorTooltipItem {
  display: flex;
  padding: 12px 9px;
  align-items: center;

  span {
    margin-right: 8px;
  }
}
.userName {
  word-break: break-all;
  min-height: 20px;
  color: $geodocs--transmittals-initiator--user-name--color;
}

.taskTeam {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 20px;

  span {
    margin-right: 8px;
  }
}

.teamIcon {
  color: $geodocs--transmittals-initiator--team-icon--color !important;
}
