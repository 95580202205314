@import '../../../../styles/Variables';

.item {
  border: 1px solid $geodocs--file-information-history-release--border--color;
  padding: 24px;

  & > div + div {
    margin-top: 10px;
  }
}

.revision {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.suitability {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.buttonFullWidth {
  width: 100%;
}
