@import '../../../../styles/Variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  .uploadColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    .uploadHeader {
      order: -1;
      flex: 1 0 100%;
    }
  }
}

.filesTable {
  max-height: 430px;
  overflow-y: auto;
  & thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

%uploadForm {
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  background: $geodocs--upload-form--background-color;
  max-width: 636px;
}

.uploadForm {
  @extend %uploadForm;
}

.uploadButtons {
  @extend %uploadForm;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.dropdown {
  margin-bottom: 15px;
}

.input {
  margin-top: 5px;
}

.validationError {
  width: 100%;
  text-align: center;
  color: $geodocs--upload-form--wrong-file-extension-text--color;
}

.validationCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.duplicated {
  color: $geodocs--upload-file-duplicate-status--color;
  background-color: $geodocs--upload-file-duplicate-status--color;
}

.warningIcon {
  color: $geodocs--upload-file-warning-status--color;
}

.checkIcon {
  color: $geodocs--upload-file-ready-status--color;
}
