@import '../../../../styles/Variables';

.previewBox {
  border: 1px solid $geodocs--file-information-details--preview-image--border-color;
  width: 320px;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fieldListHeading {
  margin: 0;
  padding: 24px 0 8px 0;
  font-size: 18px;
}

.fieldList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.field {
  display: flex;
  flex-direction: row;
  padding: 11px 0;
  box-shadow: inset 0px -1px 0px $geodocs--file-information-details--field--box-shadow-color;
  font-size: 14px;
}

.fieldLabel {
  width: 142px;
  font-weight: 700;
  padding-right: 0.5rem;
}

.fieldValue {
  width: 186px;
}
