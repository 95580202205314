@import '../../../../styles/Variables';

.searchBox {
  height: 24px;
  margin-bottom: 2px;

  input {
    height: 27px !important;
  }
}
