@import '../../../../../styles/Variables';

.supersedeModal {
  h1 {
    margin: 24px 0;
  }

  .fileWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
    padding: 0 16px 0 16px;
    background-color: $geodocs--file-name-wrapper--background-color;
  }

  .filenameCell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon {
      font-size: 16px;
    }
  }

  .previewIcon {
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .lockedContent {
    color: $geodocs--locked-content-color;
  }

  .searchContentWrapper {
    padding-top: 16px;

    a {
      cursor: pointer;
    }

    .searchMessage {
      font-size: 20px;
      color: $geodocs--locked-content-color;
      padding-top: 16px;
    }
  }
}
.filesTable {
  max-height: 250px;
  overflow-y: auto;
  & thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
