.modalActions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-top: 24px;
  position: relative;

  &:before {
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    height: 1px;
    left: -50%;
    position: absolute;
    top: 0;
    width: 200%;
  }
}
