@import '../../../../styles/Variables';

.item {
  background-color: $geodocs--file-information-history-upload--background-color;
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $geodocs--file-information-history-upload--color;
}

.icon {
  font-weight: 400;
  font-size: 14px;
  color: $geodocs--file-information-history-upload--icon--color;
}

.title {
  color: $geodocs--file-information-history-upload--title--color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  & > * + * {
    margin-left: 10px;
  }
}

.user {
  margin-left: 24px;
}
