@import '../../../styles/Variables';

.navbar {
  background-color: $geodocs-nav-bar--background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hamburger {
  border-style: none;
  border-radius: 50%;
  background-color: $geodocs-nav-bar--hamburger--background-color;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  &:hover:enabled,
  &:focus:enabled {
    border-color: $geodocs-nav-bar--hamburger--hover--border-color;
    background-color: $geodocs-nav-bar--hamburger--hover--background-color;
  }
}

.logo {
  margin-left: 50px;
  height: 40px;
}

.projectSelector {
  margin-left: 40px;
}

.fill {
  flex-grow: 1;
}

.menuIcon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $geodocs-nav-bar--menu-icon--color;
}

.menuItemsContainer {
  margin-top: 60px;
}
