.fileSelectorDropdown {
  min-width: 700px !important;
}

.dropdownSelected {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 14px;
  margin-right: 10px;
}

.selectedProgramme {
  font-size: 14px;
  margin-right: 10px;
}

.selectedProject {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
}
