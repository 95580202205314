@import '../../../styles/Variables';

.tabContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.transmittalList {
  padding: 16px;
  height: calc(100% - 41px - 180px);
  overflow: auto;
}

.noDataWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .noDataMessage {
    text-align: center;
  }
}

.paginationWrapper {
  position: absolute;
  bottom: 0;
  height: 115px;
  width: 100%;
  background-color: $geodocs--white;
}

.paginationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 20px;
  .paginationFlexGrow {
    position: absolute;
    right: 48px;
    padding-top: 0.9375rem;
  }
}

.modalOverlay {
  background-color: $geodocs--transmittals-tab-container-progress--overlay--background-color;
  opacity: 0.9;
}

.savingSpinner {
  padding-left: 16px;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: $geodocs--transmittals-tab-container-progress--overlay--spinner-z-index;
}
