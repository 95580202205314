@import '../../../../../styles/Variables';

.modal {
  width: $geodocs--modal--width;
  box-sizing: border-box;
}

.deliveryTeamsContainer {
  max-height: 440px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
}

.addDeliveryTeamButton {
  width: 100%;
  background-color: $geodocs--team-settings--add-delivery-team-button--background-color;
  border-color: $geodocs--team-settings--add-delivery-team-button--border-color;
  color: $geodocs--team-settings--add-delivery-team-button--color;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  font-weight: 700;
  font-size: 14px;
  margin-top: 16px;
}

.addDeliveryTeamButtonIcon {
  font-size: 15px;
}

.deliveryTeam {
  background-color: $geodocs--team-settings--delivery-team--background-color;
  color: $geodocs--team-settings--delivery-team--color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  padding-left: 12px;
  margin-top: 16px;
  :global(.tooltip-ref) {
    display: flex;
  }
}

.deliveryTeamAddButton {
  color: $geodocs--team-settings--delivery-team--add-button--color;
  background-color: $geodocs--team-settings--delivery-team--add-button--background-color;
  border-color: $geodocs--team-settings--delivery-team--add-button--border-color;

  &:hover:enabled:not(:active) {
    color: $geodocs--team-settings--delivery-team--add-button--hover--color;
    background-color: $geodocs--team-settings--delivery-team--add-button--hover--background-color;
    border-color: $geodocs--team-settings--delivery-team--add-button--hover--border-color;
  }
}

.taskTeamList {
  margin-bottom: 16px;
  list-style: none;
  padding: 0;
}

.taskTeam {
  font-weight: 400;
  font-size: 14px;
  padding: 12px;
  border-bottom: solid 1px $geodocs--team-settings--task-team--border-color;
}
