@import '../../../../../styles/Variables';

.fromWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.uploadForm {
  display: flex;
  flex-direction: column;
  background: $geodocs--upload-form--background-color;
  height: calc(100% - 90px);
  align-items: center;
  justify-content: center;
  min-height: 225px;
  padding-top: 15px;
}

.validationError {
  width: 100%;
  text-align: center;
  color: $geodocs--upload-form--wrong-file-extension-text--color;
  padding: 0 24px;
}
