@import '../../../../styles/Variables';

.list {
  min-width: 160px;
  :global {
    div.combobox-content {
      .field-wrapper > label .input-field {
        min-width: 180px !important;
        display: flex;
        position: initial !important;
      }
      .field-wrapper > label .input-field.is-open,
      .field-wrapper > label .input-field:hover {
        background-color: $geodocs--ascent; 
      }
      
      .field-wrapper > label .input-field.is-dropdown {
        .dropdown-icon {
          position: initial !important;
          margin-right: 3px;
        }
        .clear-icon {
          position: initial !important;
        }
      }
      .field-wrapper > label .input-field.is-dropdown input {
        font-size: 11px;
        height: 24px;
      }
      .options-wrapper .options .ellipsis {
        height: 24px !important;
        font-size: 11px !important;
        line-height: 24px !important;
        overflow: unset;
      }
    }
  }
}
