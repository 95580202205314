@import '../../../styles/Variables';

.container {
  height: calc(100% - 72px);
  overflow: auto;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}
