%column {
  float: left;
  margin-bottom: 33px;
}

.contentHeading, .contentBody {
  padding: 0 24px;
  margin-bottom: 16px;
}

.panel {
  width: 100%;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.halfRow {
  @extend %column;
  width: 50%;
}

.fullRow {
  @extend %column;
  width: 100%;
}

.labelText{
  font-weight: bold;
  margin-bottom: 17px;
  padding: 0 24px;
}

.contentText{
  padding: 0 24px;
}