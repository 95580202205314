@import '../../../../styles/Variables';

.filenameCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .lockIcon {
    font-size: 16px;
  }
}
