@import '../../../styles/Variables';

.detailsPanel {
  display: flex;
  flex-grow: 1;
  .taskdetails {
    flex: 1 0 0;
  }
  .panel {
    background-color: $geodocs--task-information--background-color;
    padding: 24px 48px;
    font-size: 14px;
    font-weight: 400;
  }

  .fileDetails {
    position: relative;
    border-left: 4px solid $geodocs--task-information-details--panel--border-color;
  }

  .panelHeading {
    font-size: 24px;
    margin: 0 0 32px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
      margin-left: 55px;
    }
  }

  .panelBody {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .taskStatus {
    font-size: 14px;
  }

  .tab {
    margin: 0 28px;
  }

  .tabContent {
    margin: 0 20px;
  }
}
