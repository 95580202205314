@import '../../../styles/Variables';

.notStarted {
  background-color: $geodocs--task-status-indicator--not-started--background-color;
}

.awaitingReview {
  background-color: $geodocs--task-status-indicator--awaiting-review--background-color;
}

.inProgress {
  background-color: $geodocs--task-status-indicator--in-progress--background-color;
}

.approved {
  background-color: $geodocs--task-status-indicator--approved--background-color;
}

.rejected {
  background-color: $geodocs--task-status-indicator--rejected--background-color;
}
