@import '../../../../styles/Variables';

.modal {
  width: $geodocs--modal--width;
  box-sizing: border-box;
  height: auto;
}

.additionalRole {
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
}

.approver {
  margin-top: 16px;

  div:global(.checkbox) {
    border-color: $geodocs--add-task-team-member--approver--border-color;
  }

  div:global(.checkbox.is-checked) {
    background-color: $geodocs--add-task-team-member--approver--checked--background-color;
  }
}
