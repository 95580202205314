@import '../../../../styles/Variables';

.wrapper {
  display: flex;
  border-bottom: 1px solid #61ccc4;
  align-items: center;

  .dropdown {
    min-width: 80px;
    margin-right: 1rem;
  }

  .datePicker {
    :global {
      div.date-component {
        .react-daterange-picker,
        .react-date-picker,
        .react-date-picker__wrapper > button {
          height: 27px !important;
          border-bottom: 0;
        }
      }
    }
  }
}
