.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 700px;
  font-size: 14px;

  * + * {
    margin-left: 10px;
  }
}
