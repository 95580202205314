@import '../../../../styles/Variables';

:global(.tooltip-ref) {
  display: flex;
}

.gap {
  margin-top: 24px;
}

.tooltip {
  color: $geodocs--project-user-management--appointed-parties--user-table--tooltip--color;
}

.addUserDeliveryTeam {
  color: $geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--color !important;
  border-color: $geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--border-color !important;
  background-color: $geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--background-color !important;

  &:hover {
    color: $geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--hover--color !important;
  }
  &:active {
    color: $geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--active--color !important;
  }
  &:focus {
    color: $geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--focus--color !important;
  }
}

.addTaskTeamMember {
  color: $geodocs--project-user-management--appointed-parties--user-table--add-task-team-member--color !important;
  border-color: $geodocs--project-user-management--appointed-parties--user-table--add-task-team-member--border-color !important;
  background-color: $geodocs--project-user-management--appointed-parties--user-table--add-task-team-member--background-color !important;
} 