@import '../../../styles/Variables';

.panel {
  width: calc(100% - 431px);
  padding: 0 24px 0 0;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
}

.column {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
  margin-top: 16px;
}

.divider {
  border: 1px solid $geodocs--divider--color;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  margin-top: 16px;
}

.label {
  width: 120px;
  font-weight: 700;
}

.message {
  white-space: pre-wrap;
  margin-bottom: 8px;
}
