@import '../../../../../styles/Variables';

.container {
  display: flex;
  align-items: stretch;
  margin-bottom: 4px;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;
  .row {
    display: flex;
  }
}

.name {
  flex: 1 1 376px;
  display: inline-block;
  margin-right: 8px;

  input {
    border-bottom-color: $geodocs--team-settings-input--name--border-bottom-color !important;
  }
}

.transmittalCode {
  flex: 1 1 228px;
  display: inline-block;
  margin-right: 8px;

  input {
    border-bottom-color: $geodocs--team-settings-input--transmittalCode--border-bottom-color !important;
  }
}

.add {
  background-color: $geodocs--team-settings-input--add--background-color;
  border: none;
  color: $geodocs--team-settings-input--add--color;

  &:disabled {
    background-color: $geodocs--team-settings-input--add--disabled--background-color;
  }

  &:active:enabled,
  &:hover:enabled {
    background-color: $geodocs--team-settings-input--add--hover--background-color;
    color: $geodocs--team-settings-input--add--hover--color;
  }
}

.validation {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  i {
    padding: 12px 12px 0px 24px;
    font-size: 14px;
  }
  .error {
    display: flex;
    align-items: flex-end;
    color: $geodocs--team-settings-input--add--validation--error--color;
  }
  .valid {
    color: $geodocs--team-settings-input--add--validation--valid--color;
  }
}
