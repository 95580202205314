@import '../../../../styles/Variables';
.modal {
  width: 500px;
  max-height: 800px;
  .modalBody {
    overflow-y: auto;
    max-height: 500px;
    scrollbar-width: thin;
  }
}
