@import '../../../styles/Variables';

.mapViewer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.frameContainer {
  flex: 0 0 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $geodocs-visualisation--frame-container--background-color;
  transition: height 300ms ease-out;
  .tabContent {
    overflow-y: auto;
    height: 100%;
  }
}

.tabBar {
  height: 55px;
  background-color: $geodocs-visualisation--frame-tab-bar--background-color;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tabBarSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabBarButton {
  height: 55px;
  background-color: $geodocs-visualisation--frame-tab-bar-button--background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: none;

  &:hover:enabled,
  &:focus:enabled {
    background-color: $geodocs-visualisation--frame-tab-bar-button--hover--background-color;
    color: $geodocs-visualisation--frame-tab-bar-button--hover--color;
  }
}

.tabBarIcon {
  margin-right: 8px;
}

.activeTabBarButton {
  background-color: $geodocs-visualisation--frame-tab-bar-button--active--background-color;
  color: $geodocs-visualisation--frame-tab-bar-button--active--color;
  font-weight: 700;
}

.dragButton {
  height: 55px;
  width: 60px;
  border-style: none;
  padding: 0;
  background-color: $geodocs-visualisation--frame-tab-bar-drag-button--background-color;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
    width: 22px;
    height: 22px;
  }

  &:hover:enabled,
  &:focus:enabled {
    background-color: $geodocs-visualisation--frame-tab-bar-drag-button--hover--background-color;
    svg {
      fill: $geodocs-visualisation--frame-tab-bar-drag-button--hover--color;
    }
  }
}

.exapandCollapseButton {
  height: 55px;
  width: 60px;
  border-style: none;
  padding: 0;
  background-color: $geodocs-visualisation--frame-tab-bar-expand-collapse-button--background-color;

  &:hover:enabled,
  &:focus:enabled {
    background-color: $geodocs-visualisation--frame-tab-bar-expand-collapse-button--hover--background-color;
    color: $geodocs-visualisation--frame-tab-bar-expand-collapse-button--hover--color;
  }
}

.expanded {
  transform: rotate(180deg);
}
