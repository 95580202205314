@import '../../../../styles/Variables';

.searchBoxContainer {
  margin-top: 15px;
}

.userPill {
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: $geodocs--add-transmittal-message-visibility-selector--user-pill--background-color;
  color: $geodocs--add-transmittal-message-visibility-selector--user-pill--color;

  & + .searchBoxContainer {
    margin-top: 7px;
  }
}

.label {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.required {
  position: absolute;
  color: $geodocs--user-selector--required--color;
  margin-left: 8px;
  margin-top: 4px;
}

.disable {
  color: $geodocs--add-transmittal-message-notify-selector--disable--color;
  pointer-events: none;
}

.userCount, .teamDetails {
  float: right;
}

.teamDetails {
  i {
    margin-right: 10px;
  }
}