@import '../../../styles/Variables';

.notifiedUserWrapper {
  display: flex;
  align-items: center;
  .extraNumber {
    margin-left: 8px;
  }
}

.initiatorTooltipItem {
  display: flex;
  align-items: center;
}

.initiatorUserTooltip {
  display: flex;
  flex-direction: column;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: clip;
  font-size: 14px;
  width: 100%;

  .userName {
    font-weight: 400;
    line-height: 21px;
    color: $geodocs--transmittals-notifier--user-name--color;
  }

  .taskTeam {
    color: $geodocs--transmittals-notifier--task-team--color;
    line-height: 16px;
  }

  .userItem {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;

    span.userCircle {
      width: 31px;
    }
    span.displayName {
      margin-left: 8px;
      text-overflow: ellipsis;
    }
  }
}
