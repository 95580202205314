@import '../../../styles/Variables';

.transmittalItem {
  display: flex;
  margin-bottom: 16px;

  .checkboxColumn {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .itemDetailWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px;
    background-color: $geodocs--transmittals-item--item-detail-wrapper--background-color;

    .subHeading {
      display: flex;
      align-items: center;

      span.transmittalTitle {
        font-weight: 700;
        font-size: 14px;
      }

      button:hover:enabled {
        color: $geodocs--black;
      }
      .linkBtn {
        display: flex;
        position: relative;
        justify-content: center;
        white-space: nowrap;
        cursor: pointer;
        outline: none;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        height: 40px;
        font-size: 14px;
        color: $geodocs--button--color;
        background-color: $geodocs--button--background-color;
        border: 1px solid $geodocs--button--border-color;
        text-decoration: none;

        .linkContent {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          color: $geodocs--black;
          background-color: $geodocs--ascent;
        }
      }
    }

    .transmittalMessage {
      font-size: 14px;
      line-height: 21px;

      p {
        display: inline;
      }

      .transmittalMessageSubject {
        font-weight: 700;
        color: $geodocs--transmittals-item--message-subject--color;
      }
    }
  }
}

.messageGroup {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  .subject {
    margin-bottom: 0;
  }

  .titleItem {
    display: flex;
    align-items: center;
    width: 100%;

    .transmittalId {
      display: flex;
      margin-right: 1rem;
    }

    .iconWrapper {
      display: flex;
      margin-left: 24px;
      align-self: flex-start;
      align-items: center;
    }

    .overdue {
      margin-right: 7px;
      width: 12px;
      height: 12px;
      background-color: $geodocs--transmittals-item--overdue--background-color;
    }

    .closed {
      color: $geodocs--transmittals-item--closed--background-color;
    }

    .flagged {
      color: $geodocs--transmittals-item--flagged--background-color;
      line-height: 17px;
    }
  }
}

.propertyLabel {
  color: $geodocs--transmittals-item--label--color;
  font-weight: 700;
  line-height: 150%;
  width: 100px;
  font-size: 14px;
}

.transmittalProperties {
  display: flex;
  justify-content: space-between;

  .propertyGroup {
    display: flex;
    flex-direction: column;
    min-width: 130px;

    .itemProperty {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      height: 31px;
      font-size: 14px;

      .initiatorGroup {
        display: flex;
        align-items: center;
      }
    }
    .lastItem {
      margin-bottom: 0;
    }
  }

  .statusSummary {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;

    .summaryItem {
      display: flex;
      align-items: center;

      span {
        margin-left: 8.67px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

.infoIcon {
  color: $geodocs--transmittals-item--info--icon--colour !important;
}
