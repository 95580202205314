@import '../../../styles/Variables';

.accordianHeading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contentFiles {
  button.downloadAllButton {
    margin: 24px 0 0 0;
    float: right;
    color: $geodocs--button-secondary--color;
    border: 1px solid $geodocs--button--background-color;
    background-color: $geodocs--button-secondary--background-color;

    &:hover:enabled:not(.is-loading) {
      color: $geodocs--transmittals-details-filedetails-downloadallbutton;
    }
  }
}

.hidden {
  display: none;
}
