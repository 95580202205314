@import '../../../styles/Variables';

.dropzone {
  background: $geodocs--dropzone--background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 160px;
  outline: none;
  text-align: center;

  &.disabled {
    background-color: $geodocs--dropzone--disabled-background-color;
  }

  &.supersede {
    padding: 20px 180px;
  }
}

.dropzoneInfo {
  line-height: 200%;
}
