@import '../../../../../styles/Variables';

.container {
  margin: 0 auto;
}

.createForm {
  display: flex;
  flex-direction: column;
  padding: 25px;
  padding-left: 0;
}

.expandCreateForm {
  background: $geodocs--upload-form--background-color;
  padding-left: 25px;
}

.fromWrapper {
  display: flex;
  flex-direction: column;
  width: 80px;
  transition: width .2s;
  padding-left: 24px;
}

.expand {
  width: 360px;
}

.cardHeader {
  height: 90px;
}

.metadataItem {
  display: flex;
}

.formControl {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0;

  div:global(.dropdown-wrapper .dropdown-label) {
    line-height: 22px;
  }
}

.none {
  display: none;
}

.createButtons {
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.applyMetadataBtnWrapper {
  padding-bottom: 12px;
  padding-right: 12px;
  align-items: flex-end;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.applyMetadataBtn {
  margin-left: auto;
}

.buttonLink {
  &:hover {
    text-decoration: underline !important;
  }
}

.dropdown {
  div:global(.dropdown-content) {
    div:global(.dropdown-selected) {
      background-color: $geodocs--upload-form--dropdown--background-color;
    }
    div:global(.dropdown-options) {
      background-color: $geodocs--upload-form--dropdown--background-color;
    }
    div:global(.dropdown-selected.is-disabled),
    div:global(.dropdown-selected.is-disabled:hover) {
      background-color: $geodocs--upload-form--dropdown--disabled-background-color;
    }
  }
}

.input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.requireText {
  color: $geodocs--upload-form--text--color;
  font-size: 12px;
}

.collapseBtn {
  background-color: $geodocs--upload-form--background-color;
  font-weight: 700;
  border: 0;
}

.expandBtn {
  background-color: $geodocs--upload-form--metadata--expand-button--background-color;
  border: 0;
  box-shadow: 0px 2px 4px $geodocs--upload-form--metadata--expand-button--box-shadow-color;
  font-weight: 700;
}

.txtLimit {
  color: $geodocs--upload-form--text--color;
  font-size: 12px;
  margin-top: 10px;
  text-align: end;
}

.formHeader {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 16px;
}
