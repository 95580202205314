@import '../../../styles/Variables';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;

  h1 {
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }
}

.detailsPanel {
  display: flex;
  .panel {
    padding: 0 40px;
    font-size: 14px;
    height: calc(100% - 245px);
    overflow-y: auto;
    flex-grow: 1;

    hr {
      border-top: 1px solid $geodocs--transmittal-details--panel--border-color;
    }
  }

  .fileDetails {
    position: relative;
    border-left: 4px solid $geodocs--transmittal-details--panel--border-color;
  }
}

.subHeading {
  background-color: $geodocs--transmittal-details--sub-heading--background-color;
  display: flex;
  align-items: center;
  padding: 0 26px;
  height: 35px;

  h2 {
    display: inline-block;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: $geodocs--transmittals-item--closed--background-color;
    
    .transmittalTitle {
      margin-left: 1rem;
      font-weight: 700;
      color: $geodocs--black;
    }
  }
}
.iconWrapper {
  display: flex;
  margin-left: 24px;
}

.closed {
  color: $geodocs--transmittals-item--closed--background-color;
}

.flagged {
  font-size: 18px;
  color: $geodocs--transmittal-details--flagged--color;
  margin-left: 14px;
}

.overdue {
  background-color: $geodocs--transmittal-details--overdue--background-color;
}

.fields {
  list-style: none;
  padding: 0;
}

.label {
  font-weight: 700;
  width: 100px;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.labelIcon {
  font-size: 16px;
  margin-right: 18px;
}

.fieldValue {
  display: flex;
  align-items: center;
  height: 50px;
}

.column {
  display: inline-flex;

  div:nth-child(2) {
    width: 310px;
  }
}

.teamIcon {
  font-size: 16px;
  margin: 0 12px;
}

.initiatorName {
  margin-left: 8px;
}

.message {
  margin: 32px 0;
  p {
    display: inline;
    font-size: 14px;
  }
}
