@import '../../../../../styles/Variables';

.modalOverlay {
  background-color: $geodocs--upload-progress--overlay--background-color;
  opacity: 0.9;
}

.modal {
  min-width: 500px;
  max-width: 50%;
  position: relative;
  z-index: $geodocs--upload-progress--modal--z-index;
  background-color: transparent;
  overflow: hidden;
  & h4,
  p {
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
  }
  & p,
  a {
    font-size: 12px;
    line-height: 30px;
    color: $geodocs--black;
  }
  .fileNameBox {
    border: none;
    padding: 5px;
    width: 300px;
    height: 100px;
    overflow: auto;
    .fileNames {
      margin: 0px;
      font-weight: 400;
    }
  }
}

.filler {
  height: 70%;
  background-color: $geodocs--upload-progress--bar--background-color;
  border-radius: inherit;
}

.container {
  height: 5px;
  width: 100%;
  background-color: $geodocs--upload-progress--container--background-color;
  border-radius: 10px;
  margin: 5px 10px 5px 0px;
}

.label {
  padding: 5px;
  color: $geodocs--black;
  font-weight: bold;
}
