@import '../../../../styles/Variables';

.fileTable {
  height: calc(100% - 130px);
  overflow: auto;
  table {
    width: auto;
    td:first-child,
    td:nth-child(1) {
      left: 0;
      background-color: $geodocs--file-table--primary-columns--background-color !important;
    }
    td:last-child {
      position: sticky;
      right: 0;
      background-color: $geodocs--file-table--action-column--background-color !important;

      &:hover {
        z-index: $geodocs--file-table--action-column--hover--z-index;
      }
    }
  }
  thead {
    :global {
      tr.table-row.is-header {
        > td.table-cell {
          white-space: nowrap;
        }
      }
    }
  }
}

.tableHeadColumn {
  width: fit-content;
}

.paginationWrapper {
  position: absolute;
  bottom: 0;
  height: 130px;
  width: 100%;
}

.paginationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 52px;
  padding-top: 30px;
  .paginationFlexGrow {
    position: absolute;
    right: 48px;
    padding-top: 0.9375rem;
  }
}
