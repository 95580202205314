@import '../../../styles/Variables';

.cardContainer {
  font-size: 12px;
  border: 1px solid $geodocs--project-settings--project-card--border-color;
  display: flex;
  flex-direction: column;
  margin: 12px;
  width: 300px;
}

.cardContent {
  border-bottom: 1px solid $geodocs--project-settings--project-card--border-color;
  padding: 16px 16px 14px;
  flex: 1;
}

.lineWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;

  span.boldLabel {
    font-weight: bold;
  }
}

.projectInfo {
  font-weight: normal;
  padding: 8px 0 16px 0;
}

.cardId {
  font-weight: bold;
  padding: 0 0 8px 0;
}

.cardTitle {
  font-size: 16px;
  padding: 0 0 8px 0;
  font-weight: bold;

  span {
    line-height: 27px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
}

.actionButtons {
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: flex-end;
}

.menuIcon {
  padding: 10px;
}

.menuItemsContainer {
  margin-top: 45px;
}
