@import '../../../styles/Variables';

.card {
  padding: 16px;
  background-color: $geodocs--transmittal-response-card--card--background-color;
  margin-bottom: 16px;
  font-size: 14px;
}

.responseName {
  margin-left: 5px;
}

.responseTime{
  margin-left: auto; 
  margin-right: 0;
}

.fields {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fieldValue {
  display: flex;
  align-items: center;
  height: 40px;
}

.responseMessage {
  color: $geodocs--transmittal-response-card--message--color;
  font-size: 12px;

  p {
    display: inline;
    font-size: 12px;
  }
}

.responseDetails {
  overflow: hidden;
  padding: 10px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  cursor: pointer;
}

.responseDetailShow {
  overflow: visible;
  white-space: initial;
  cursor: initial;
}

.icon {
  font-size: 16px;
}

.countValue {
  margin-left: 8px;
  margin-right: 24px;
}

.cardFocus {
  border: $geodocs--transmittal-response-card-focus--border;
}