@import '../../../styles/Variables';

.topPane {
  margin: 24px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.cardView {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0 12px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  position: relative;
}

.paginationFlexGrow {
  position: absolute;
  right: 24px;
  top: 15px;
}
