@import '../../../../../styles/Variables';

.container {
  margin: 0 auto;
  width: 676px;
}

.dropzone {
  background: $geodocs--supersede-file-upload--file-drop-zone--background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 160px;
  width: 588px;
  outline: none;
  text-align: center;
}

%uploadForm {
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  background: $geodocs--supersede-file-upload--upload-form--background-color;
  max-width: 636px;
}

.uploadForm {
  @extend %uploadForm;
}

.uploadButtons {
  @extend %uploadForm;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.uploadedFiles {
  margin-top: 30px;
}

.dropdown {
  margin-bottom: 15px;
}

.input {
  margin-top: 5px;
}

.errorSummary {
  text-align: center;
  color: $geodocs--supersede-file-upload--upload-form--error-summaries-color;
}
