@import '../../../styles/Variables';

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: 72px 1fr;
  grid-template-areas:
    'actionBar actionBar'
    'content fileInformation';
  overflow: hidden;
}

.showFileInformation {
  grid-template-columns: 1fr 368px;
}

.actionBar {
  grid-area: actionBar;
}

.content {
  grid-area: content;
  overflow: auto;
  position: relative;
}

.fileInformation {
  grid-area: fileInformation;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 4px $geodocs--content--tab-container--box-shadow-color;
}

.modalOverlay {
  background-color: $geodocs--content--tab-container-progress--overlay--background-color;
  opacity: 0.9;
}

.savingSpinner {
  padding-left: 16px;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 1001;
}

.toolTipBtn {
  padding-left: 12px;
  padding-right: 12px;
}