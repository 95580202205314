@import '../../../../styles/Variables';

.addingPanel {
  display: flex;
  .panel {
    padding: 0 40px;
    font-size: 14px;
    height: calc(100% - 245px);
    overflow-y: auto;
    width: 100%;

    hr {
      border-top: 1px solid $geodocs--transmittal-details--panel--border-color;
    }
  }

  .fileDetails {
    position: relative;
    border-left: 4px solid $geodocs--transmittal-details--panel--border-color;
  }
}

.separator {
  padding-bottom: 40px;
  border-bottom: 1px solid $geodocs--transmittal-details--panel--border-color;
}

.subHeading {
  background-color: $geodocs--transmittal-details--sub-heading--background-color;
  display: flex;
  align-items: center;
  padding: 0 24px;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    padding: 5px 0;
    margin: 0;
  }
}

.actionBar {
  border-bottom-style: solid;
  border-top-style: solid;
}

.flagged {
  font-size: 18px;
  color: $geodocs--transmittal-details--flagged--color;
  margin-left: 14px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  font-size: 14px;

  h1 {
    display: inline-block;
  }
}

.panel {
  margin-top: 20px;
  padding: 0 40px;
  font-size: 14px;
  height: calc(100% - 245px);
  overflow-y: auto;
  margin-bottom: 60px;
}



.supportingFiles {
  margin: 24px 0 12px 0;
  .supportingFilesAccordion {
    margin: 16px 0 0;

    .accordionHeading {
      display: flex;
      align-items: center;

      svg {
        margin: 0 16px 0 0;
      }
    }
  }
}

.labelIcon {
  font-size: 16px;
  margin-right: 18px;
}

.teamIcon {
  font-size: 16px;
  margin: 0 12px;
}

.initiatorName {
  margin-left: 8px;
}

$label-width: 200px;
$see-more-link-width: 100px;

.row,
.rowDisplay {
  display: flex;
  font-size: 14px;
  margin-bottom: 5px;
}

.rowDisplay {
  margin: 12px 0;
}

.row > div:nth-child(2) {
  flex: 1;
}
.rowDisplay > div:nth-child(4) {
  flex: 1;
}

.label,
.userSelectorLabel {
  font-weight: bold;
  width: $label-width;
}

.label {
  margin-top: 10px;
}

.userSelectorLabel {
  margin-top: 20px;
}

.textEditor,
.formInput,
.userSelector,
.threeUsersCircle,
.displayValue {
  display: inline-block;
}

.textEditor,
.formInput {
  width: calc(100% - $label-width);
}

.displayValue {
  width: calc((100% - 400px) / 2);
  margin-top: 10px;
}

.userSelector {
  width: calc(100% - $label-width - $see-more-link-width);
}

.seeMoreLinkSection {
  width: $see-more-link-width;
  text-decoration: underline;
}

.teamChartBtnWrapper {
  margin-left: 2rem;
  display: flex;
  .teamChartBtn {
    margin-top: auto;
  }
}

.seeMoreLink {
  text-decoration: underline !important;
  margin-top: 15px;
  font-size: 14px;
  color: $geodocs--add-transmittal-response--see-more-link--color !important;
  padding-right: 0px;
}

.requiredSymbol {
  color: $geodocs--add-transmittal-response--required-symbol--color;
}
