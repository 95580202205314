@import '../../../../../styles/Variables';

.container {
  display: flex;

  .uploadColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    width: 100%;
    .uploadHeader {
      order: -1;
      flex: 1 0 100%;
    }
  }
}

.metadataWrapper {
  width: auto;
  flex-direction: row;
}

.uploadWrapper {
  width: auto;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}

.rightContainer {
  display: flex;
  flex-direction: column;
}

.uploadedFilesContainer {
  padding: 24px;
  background-color: $geodocs--upload-form--background-color;

  .uploadedFileHeading {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .numberUploadFiles {
      font-size: 14px;
      font-weight: bold;
    }

    .actionButtons {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 16px;
      }
    }
  }

  .uploadedFilesEnd {
    padding: 24px 0 0;

    .totalFiles {
      font-size: 14px;
      color: $geodocs--button--disabled--color;
    }
  }

  .filesTable {
    max-height: 660px;
    min-height: 310px;
    overflow: auto;

    & thead {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .fileSizeHeader {
      margin: 0 70px;
    }

    td.supersedeCell {
      background-color: $geodocs--upload-form--dropdown--disabled-background-color !important;
      color: $geodocs--upload-form--supersede--metadata-color;
    }

    .status {
      padding: 6px 12px;
      border-radius: 4px;
      font-size: 11px;
      float: right;
      width: max-content;
      margin-bottom: 5px;

      &.ready {
        color: $geodocs--upload-file-ready-status--color;
        border: 1px solid $geodocs--upload-file-ready-status--color;
        background-color: $geodocs--upload-file-ready-status--background;
      }

      &.duplicate {
        color: $geodocs--upload-file-duplicate-status--color;
        border: 1px solid $geodocs--upload-file-duplicate-status--color;
        background-color: $geodocs--upload-file-duplicate-status--background;
      }

      &.missingMetadata {
        color: $geodocs--upload-file-duplicate-status--color;
        border: 1px solid $geodocs--upload-file-duplicate-status--color;
        background-color: $geodocs--upload-file-duplicate-status--background;
      }

      &.supersede {
        color: $geodocs--upload-file-supersede-status--color;
        border: 1px solid $geodocs--upload-file-supersede-status--color;
        background-color: $geodocs--upload-file-supersede-status--background;
      }

      &.missingFileExtension {
        color: $geodocs--upload-file-duplicate-status--color;
        border: 1px solid $geodocs--upload-file-duplicate-status--color;
        background-color: $geodocs--upload-file-duplicate-status--background;
      }
    }

    td:first-child {
      border-left: 0;
      position: sticky;
      left: 0;
      z-index: 10;
    }

    td:last-child {
      position: sticky;
      right: 0;
      background-color: $geodocs--file-table--action-column--background-color !important;
      border-right: 1px solid $geodocs--white;

      &:hover {
        z-index: $geodocs--file-table--action-column--hover--z-index;
      }
    }
  }
}

.fromWrapper {
  display: flex;
  flex-direction: column;
}

.cardHeader {
  height: 90px;
}

.uploadForm {
  display: flex;
  flex-direction: column;
  background: $geodocs--upload-form--background-color;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.wrapperFileDropZone {
  width: 100%;
  padding: 0;
}

.uploadButtons {
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.dropdown {
  margin-bottom: 15px;
}

.input {
  margin-top: 5px;
}

.validationError {
  width: 100%;
  text-align: center;
  color: $geodocs--upload-form--wrong-file-extension-text--color;
  padding: 0 24px;
}
