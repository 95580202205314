@import '../../../styles/Variables';

.users {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.extra {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
}

.userPill {
  margin-left: 8px;
  background-color: $geodocs--three-user-circles--pill--background-color;
  color: $geodocs--three-user-circles--pill--color;
}
