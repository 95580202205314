@import '../../../../styles/Variables';

.container {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.subContainer {
  margin: 24px 48px 0 48px;
}

.subPageHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $geodocs--project-user-management--appointing-party--sub-page-header--color;
}

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
}

.addMembersButton {
  display: flex;
  align-items: center;
  gap: 12px;
}
