@import '../../../../../styles/Variables';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  .uploadColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    .uploadHeader {
      order: -1;
      flex: 1 0 100%;
    }
  }
}

.filesTable {
  max-height: 430px;
  overflow-y: auto;
  padding: 20px;
  width: 94%;
  & thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

%uploadForm {
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  background: $geodocs--supersede-file-upload--upload-form--background-color;
  max-width: 636px;
}
.uploadForm {
  @extend %uploadForm;
}
.uploadButtons {
  @extend %uploadForm;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
  padding-right: 15px 40px 15px 0px;
}
.uploadedFiles {
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  outline: none;
  background: $geodocs--supersede-file-upload--upload-form--background-color;
}
.dropdown {
  margin-bottom: 15px;
}
.input {
  margin-top: 5px;
}

.validationError {
  width: 100%;
  text-align: center;
  color: $geodocs--upload-form--wrong-file-extension-text--color;
}

.headerText {
  padding-left: 20px;
}
