@import '../../../../styles/Variables';

.container {
  overflow: auto;
  width: 100%;
  height: calc(100% - 140px);
}

.subContainer {
  margin: 24px 48px 0 48px;
}

.subPageHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $geodocs--project-user-management--appointed-parties--sub-page-header--color;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;

  .space {
    flex: 1;
  }

  :global(button.is-text) {
    flex: 0;
    margin-left: 0;
    font-weight: normal;
    color: $geodocs--project-user-management--appointed-parties--button-text--color;

    &:hover {
      background-color: $geodocs--project-user-management--appointed-parties--button-text--hover--background-color;
    }

    &:focus {
      background-color: $geodocs--project-user-management--appointed-parties--button-text--focus--background-color;
      border-color: $geodocs--project-user-management--appointed-parties--button-text--focus--border-color;
    }
  }
}

.teamSettingsButton {
  display: flex;
  align-items: center;
  gap: 12px;
}

.noTeamsContainer {
  margin-top: 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.noTeamsText {
  font-weight: 400;
  font-size: 24px;
  color: $geodocs--project-user-management--appointed-parties--no-teams-text--color;
}
