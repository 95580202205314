@import '../../../styles/Variables';

.users {
  padding: 0;
  width: calc(100% - 120px);
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
}

.namePanel {
  width: calc(100% - 130px);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  margin-left: 16px;
}
