@import '../../../styles/Variables';

.panel {
  padding-left: 24px;
  box-sizing: border-box;
}

.comments {
  margin-bottom: 16px;
}

.button {
  width: 125px;
}
