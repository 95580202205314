.container {
  font-weight: 400;
  font-size: 14px;
  margin-left: 24px;
  height: 100%;
  display: flex;
}

.icon {
  font-size: 16px;
}

.taskTeamTitle {
  font-weight: 700;
}
