/*
  Variables used through out Geodocs
*/

@import '../../node_modules/@aurecon-creative-technologies/styleguide/lib/scss/colours';

/*
  Colour variables
  - These should not be used directly in styles, instead use these in the usage variables below
*/

$geodocs--white: #fff;
$geodocs--black: #000;

$geodocs--ascent: #f37021;
$geodocs--ascent-light: #fdebdf;
$geodocs--ascent-dark: #e75820;

$geodocs--error: #da3b20;
$geodocs--warning: #da3b20;

$geodocs--task--assigned: #f6d510;
$geodocs--task--reassigned: #14a4de;
$geodocs--task--not-started: #00000040;
$geodocs--task--awaiting-review: #f6d510;
$geodocs--task--in-progress: #c2185b;
$geodocs--task--approved: #00bd39;
$geodocs--task--rejected: #da3b20;

$geodocs--task--task-team-review: #c2185b;
$geodocs--task--share-approval: #337bb7;
$geodocs--task--delivery-team-review: #c2185b;
$geodocs--task--publish-authorise: #00796b;
$geodocs--task--publish-review: #c2185b;
$geodocs--task--publish-accept: #00796b;
$geodocs--icon--warning: #f6d510;

/*
  Usage variables
  - Where a variable is needed it should be defined below
  - Place the variables used in the same file together under a comment with the relative file path
  - Name the variable for the usage needed using the kebab case as follows
    $geodocs--{area}--[{sub-area}--][{state}--]{property}
    For example
      $geodocs--button--hover--background-color
      $geodocs--style-guide--input--focus--background-color
  - Don't worry about having too many variables, instead just add as needed
  - Do not have variables refer to other variables defined below, only use variables defined above
*/

// src\styles\App.scss

$geodocs--text--color: $geodocs--black;
$geodocs--text--size: 16px;

$geodocs--modal--width: 708px;

$geodocs--button--background-color: #424242;
$geodocs--button--border-color: #424242;
$geodocs--button--color: $geodocs--white;
$geodocs--button--disabled--background-color: #eeeeee;
$geodocs--button--disabled--border-color: #eeeeee;
$geodocs--button--disabled--color: #757575;
$geodocs--button--hover--background-color: $geodocs--ascent;
$geodocs--button--hover--border-color: $geodocs--ascent;
$geodocs--button--hover--color: $geodocs--white;
$geodocs--button--focus--background-color: $geodocs--black;
$geodocs--button--focus--border-color: $geodocs--black;
$geodocs--button--focus--color: $geodocs--white;

$geodocs--checkbox--border-color: #424242;
$geodocs--checkbox-is-checked--background-color: #212121;
$geodocs--checkbox-is-checked--color: $geodocs--white;

$geodocs--divider--color: #d4d4d4;

// src\styles\StyleGuidOverrides.scss

$geodocs--style-guide--input--focus--background-color: $geodocs--ascent-light;

$geodocs--style-guide--tab--color: #121212;

$geodocs--style-guide--dropdown--background-color: #f2f2f2;
$geodocs--style-guide--dropdown--color: #121212;
$geodocs--style-guide--dropdown--hover--background-color: $geodocs--ascent;
$geodocs--style-guide--dropdown--hover--border-color: $geodocs--ascent;
$geodocs--style-guide--dropdown--hover--color: $geodocs--black;
$geodocs--style-guide--dropdown--icon--hover--color: $geodocs--black;
$geodocs--style-guide--dropdown--list--background-color: #fafafa;
$geodocs--style-guide--dropdown--list-item--hover--background-color: #fdebdf;
$geodocs--style-guide--dropdown--list-item--hover--color: $geodocs--black;

$geodocs--style-guide--pagination-item--color: #212121;
$geodocs--style-guide--pagination-chevron--color: #212121;

$geodocs--style-guide--search--border-color: $geodocs--black;
$geodocs--style-guide--search--focus--border-color: $geodocs--ascent;
$geodocs--style-guide--search--clear-icon--color: #666666;
$geodocs--style-guide--search--background-color: $geodocs--ascent-light;
$geodocs--style-guide--search--list-item--hover--background-color: $geodocs--ascent;

$geodocs--style-guide--button-text--font-color: #424242;
$geodocs--style-guide--button-text--hover--background-color: transparent;
$geodocs--style-guide--button-text--focus--background-color: transparent;
$geodocs--style-guide--button-text--focus--border-color: transparent;

// src\components\shared\styles\SecondaryButton.module.scss

$geodocs--button-secondary--background-color: $geodocs--white;
$geodocs--button-secondary--color: #424242;

// src\components\shared\styles\PrimaryIconButton.module.scss

$geodocs--button-icon-primary--color: #212121;
$geodocs--button-icon-primary--background-color: transparent;
$geodocs--button-icon-primary--hover--color: $geodocs--ascent;
$geodocs--button-icon-primary--hover--background-color: transparent;
$geodocs--button-icon-primary--focus--color: $geodocs--white;
$geodocs--button-icon-primary--focus--background-color: #212121;

// src\components\shared\styles\PrimaryIconButton.module.scss

$geodocs--button-icon-secondary--color: $geodocs--white;
$geodocs--button-icon-secondary--background-color: #424242;
$geodocs--button-icon-secondary--border-color: #424242;
$geodocs--button-icon-secondary--hover--color: #424242;
$geodocs--button-icon-secondary--hover--background-color: $geodocs--ascent;
$geodocs--button-icon-secondary--hover--border-color: $geodocs--ascent;
$geodocs--button-icon-secondary--focus--color: $geodocs--white;
$geodocs--button-icon-secondary--focus--background-color: #424242;
$geodocs--button-icon-secondary--focus--border-color: $geodocs--ascent;
$geodocs--button-icon-secondary--active--color: $geodocs--white;
$geodocs--button-icon-secondary--active--background-color: #212121;
$geodocs--button-icon-secondary--active--border-color: #212121;

// src\components\shared\styles\ActionBar.module.scss

$geodocs--action-bar--selected--background-color: $geodocs--ascent-light;

// src\components\shared\styles\TaskStatusIndicator.module.scss

$geodocs--task-status-indicator--not-started--background-color: $geodocs--task--not-started;
$geodocs--task-status-indicator--awaiting-review--background-color: $geodocs--task--awaiting-review;
$geodocs--task-status-indicator--in-progress--background-color: $geodocs--task--in-progress;
$geodocs--task-status-indicator--approved--background-color: $geodocs--task--approved;
$geodocs--task-status-indicator--rejected--background-color: $geodocs--task--rejected;

// src\components\shared\styles\WorkflowPill.module.scss
$geodocs--workflow-pill--text-color: $geodocs--white;
$geodocs--workflow-pill--task-team-review--background-color: $geodocs--task--task-team-review;
$geodocs--workflow-pill--share-approval--background-color: $geodocs--task--share-approval;
$geodocs--workflow-pill--delivery-team-review--background-color: $geodocs--task--delivery-team-review;
$geodocs--workflow-pill--publish-authorise--background-color: $geodocs--task--publish-authorise;
$geodocs--workflow-pill--publish-review--background-color: $geodocs--task--publish-review;
$geodocs--workflow-pill--publish-accept--background-color: $geodocs--task--publish-accept;

// src\components\shared\styles\TaskUserStatusIndicator.module.scss

$geodocs--task-user-status-indicator--awaiting-review--background-color: $geodocs--task--awaiting-review;
$geodocs--task-user-status-indicator--approved--background-color: $geodocs--task--approved;
$geodocs--task-user-status-indicator--rejected--background-color: $geodocs--task--rejected;

// src\components\content\wipTab\upload\styles\AddContent.module.scss

$geodocs--dropdown-list--background-color: #fafafa;
$geodocs--dropdown-list--z-index: 10;
$geodocs--dropdown-list--hover--background-color: $geodocs--ascent;

// src\components\content\wipTab\upload\styles\FileUpload.module.scss
$geodocs--upload-form--background-color: #fafafa;
$geodocs--upload-form--text--color: #e0452a;
$geodocs--upload-form--dropdown--background-color: $geodocs--white;
$geodocs--upload-form--dropdown--disabled-background-color: #eeeeee;
$geodocs--upload-form--wrong-file-extension-text--color: #e0452a;
$geodocs--upload-file-ready-status--background: #e6f7e8;
$geodocs--upload-file-ready-status--color: #006b00;
$geodocs--upload-file-duplicate-status--background: #fbeae9;
$geodocs--upload-file-duplicate-status--color: #c63922;
$geodocs--upload-file-supersede-status--background: #e6f7e8;
$geodocs--upload-file-supersede-status--color: #006b00;
$geodocs--upload-file-warning-status--color: #ffb400;
$geodocs--upload-form--metadata--expand-button--background-color: #fafafa;
$geodocs--upload-form--metadata--expand-button--box-shadow-color: rgba(0, 0, 0, $alpha: 0.1);
$geodocs--upload-form--supersede--metadata-color: #666666;

// src\ClientApp\src\components\files\wipTab\upload\styles\SupersedeFileUpload.module.scss
$geodocs--supersede-file-upload--file-drop-zone--background-color: $geodocs--white;
$geodocs--supersede-file-upload--upload-form--background-color: #fafafa;
$geodocs--supersede-file-upload--upload-form--error-summaries-color: #c63922;

// src\ClientApp\src\components\files\wipTab\upload\styles\UploadResultsModal.module.scss
$geodocs--upload-form--export-btn--hover-background-color: #f37021;

// src\components\layout\styles\Footer.module.scss

$geodocs--link--color: $geodocs--black;
$geodocs--link--hover--color: $geodocs--ascent-dark;
$geodocs--footer--border-top: #c4c4c4;
$geodocs--footer--background-color: $geodocs--white;

// src\components\layout\styles\SidebarButton.module.scss

$geodocs--sidebar-button--icon--color: $geodocs--black;
$geodocs--sidebar-button--color: $geodocs--black;
$geodocs--sidebar-button--background-color: $geodocs--white;
$geodocs--sidebar-button--hover--icon--color: $geodocs--ascent;
$geodocs--sidebar-button--active--background-color: rgba($geodocs--ascent, 0.1);
$geodocs--sidebar-button--active--color: $geodocs--ascent;
$geodocs--sidebar-button--active--border-left-color: $geodocs--ascent;

// src\components\layout\styles\Sidebar.module.scss

$geodocs--sidebar--box-shadow-color: rgba(0, 0, 0, 0.2);
$geodocs--sidebar--background-color: $geodocs--white;

// src\components\content\fileTable\styles\FileTable.module.scss

$geodocs--file-table--primary-columns--background-color: #fafafa;
$geodocs--file-table--action-column--background-color: #fafafa;
$geodocs--file-table--action-column--hover--z-index: 1;

// src\components\content\fileTable\styles\DateFilter.module.scss

$geodocs--file-table--date-filter-input--week-day--colour: $geodocs--black;
$geodocs--file-table--date-filter-input--weekend-day--colour: $geodocs--ascent;
$geodocs--file-table--date-filter-input--month--colour: $geodocs--black;
$geodocs--file-table--date-filter-input--year--colour: $geodocs--black;
$geodocs--file-table--date-filter-input--decade--colour: $geodocs--black;
$geodocs--file-table--date-filter-input--clear-button--hover--colour: $geodocs--black;
$geodocs--file-table--date-filter-input--calendar-button--hover--colour: $geodocs--ascent;

// src\components\tasks\styles\TaskCard.module.scss

$geodocs--task-card--background-color: #fafafa;

// src\components\shared\styles\UserCircle.module.scss

$geodocs--user-circle--tool-tip--box-shadow: #d4d4d4;
$geodocs--user-circle--tool-tip--background-color: $geodocs--white;
$geodocs--user-circle--tool-tip--color: $geodocs--black;
$geodocs--user-circle--tool-tip--email--color: #666666;
$geodocs--user-circle--tool-tip--z-index: 2;
$geodocs--user-circle--background-color: #949494;
$geodocs--user-circle--color: $geodocs--white;
$geodocs--user-circle--border-color: #e5e5e5;
$geodocs--user-circle--hover--background-color: #808080;

// src\components\shared\styles\GoBackButton.module.scss

$geodocs--go-back-button--text--color: #00000080;
$geodocs--go-back-button--arrow--color: #424242;
$geodocs--go-back-button--text--hover--color: $geodocs--black;
$geodocs--go-back-button--arrow--hover--color: $geodocs--black;

// src\components\shared\styles\FileDropZone.module.scss
$geodocs--dropzone--background-color: $geodocs--white;
$geodocs--dropzone--disabled-background-color: #eeeeee;

// src\components\taskInformation\styles\TaskInformation.module.scss

$geodocs--task-information--background-color: #fafafa;
$geodocs--task-information-details--panel--border-color: #f2f2f2;

// src\components\content\wipTab\upload\styles\ProgressBar.module.scss

$geodocs--upload-progress--bar--background-color: #e0452a;
$geodocs--upload-progress--overlay--background-color: rgb(248, 248, 248);
$geodocs--upload-progress--modal--z-index: 1010;
$geodocs--upload-progress--container--background-color: #e0e0de;

// src\components\taskInformation\styles\TaskInformationHistoryItem.module.scss

$geodocs--task-information-history-item--date--color: #757575;
$geodocs--task-information-history-item--message--background-color: #fafafa;

// src\components\layout\styles\NavBar.module.scss

$geodocs-nav-bar--background-color: #424242;
$geodocs-nav-bar--hamburger--background-color: rgba(255, 255, 255, 0.25);
$geodocs-nav-bar--hamburger--hover--border-color: $geodocs--ascent;
$geodocs-nav-bar--hamburger--hover--background-color: $geodocs--ascent;
$geodocs-nav-bar--menu-icon--color: $geodocs--white;

// src\components\shared\styles\CentreOnPage.module.scss

$geodocs--center-on-page--z-index: 1000;

// src\components\shared\styles\Modal.module.scss

$geodocs--modal--z-index: 1010;
$geodocs--modal--background-color: $geodocs--white;
$geodocs--modal--box-shadow-color: hsl(0deg 0% 7% / 40%);

// src\components\shared\styles\Overlay.module.scss

$geodocs--overlay--z-index: 1000;
$geodocs--overlay--background-color: $geodocs--black;

// src\components\shared\styles\DropDownMenu.module.scss

$geodocs--drop-down-menu--z-index: 2;
$geodocs--drop-down-menu--box-shadow--color: rgba(18, 18, 18, 0.25);
$geodocs--drop-down-menu--background-color: #fafafa;
$geodocs--drop-down-menu--icon--hover--background-color: $geodocs--ascent;
$geodocs--drop-down-menu--icon--disabled--background-color: #eeeeee;

// src\components\shared\styles\DropDownMenuButton.module.scss

$geodocs--drop-down-menu-button--color: #121212;
$geodocs--drop-down-menu-button--hover--color: $geodocs--black;
$geodocs--drop-down-menu-button--hover--background-color: $geodocs--ascent;

// src\components\content\styles\SelectedFilesLabel.module.scss

$geodocs--selected-files-label--color: $geodocs--black;
$geodocs--selected-files-warning-label--color: $geodocs--warning;
$geodocs--selected-files-link-label--color: #424242;

// src\components\content\styles\TabContainer.module.scss

$geodocs--content--tab-container--box-shadow-color: rgba(0, 0, 0, 0.1);
$geodocs--content--tab-container-progress--overlay--background-color: rgb(248, 248, 248);

// src\components\content\fileInformation\styles\Details.module.scss

$geodocs--file-information-details--preview-image--border-color: rgba(0, 0, 0, 0.1);
$geodocs--file-information-details--field--box-shadow-color: rgba(0, 0, 0, 0.1);

// src\components\content\fileInformation\styles\HistoryDate.module.scss

$geodocs--file-information-history-date--line--color: #666666;
$geodocs--file-information-history-date--line--background-color: #dadada;

// src\components\content\fileInformation\styles\HistoryRelease.module.scss

$geodocs--file-information-history-release--border--color: rgba(243, 112, 33, 0.5);

// src\components\content\fileInformation\styles\HistoryUpload.module.scss

$geodocs--file-information-history-upload--background-color: #fafafa;
$geodocs--file-information-history-upload--color: #666666;
$geodocs--file-information-history-upload--icon--color: #121212;
$geodocs--file-information-history-upload--title--color: #121212;

// src\components\content\fileInformation\styles\TaskCollapsed.module.scss

$geodocs--file-information-history-task--background-color: #fafafa;
$geodocs--file-information-history-task--color: #666666;
$geodocs--file-information-history-task--title--color: #121212;

// src\components\content\fileInformation\styles\TaskEventLabel.module.scss

$geodocs--file-information-history-task--event-label--color: #121212;

// src\components\content\fileInformation\styles\TaskExpanded.module.scss

$geodocs--file-information-history-task--expanded-title--color: #121212;

// src\components\content\reviewModal\styles\FileList.module.scss

$geodocs--review-modal--filename-remove--background-color: $geodocs--ascent-light;
$geodocs--review-modal--filename-remove--hover--background-color: $geodocs--ascent;
$geodocs--review-modal--filename--remove-icon--color: $geodocs--black;
$geodocs--review-modal--pagingation--pagination-chevron-is-disabled--color: #bdbdbd;

// src\components\content\reviewModal\styles\ErrorMessage.module.scss

$geodocs--review-modal--error-message--color: $geodocs--error;

// src\components\visualisation\styles\Frame.module.scss

$geodocs-visualisation--frame-container--background-color: $geodocs--white;

// src\components\visualisation\styles\Frame.module.scss

$geodocs-visualisation--frame-tab-bar--background-color: $aurecon--grey--700;
$geodocs-visualisation--frame-tab-bar-button--background-color: $aurecon--grey--700;
$geodocs-visualisation--frame-tab-bar-button--hover--background-color: $geodocs--white;
$geodocs-visualisation--frame-tab-bar-button--hover--color: $aurecon--grey--600;
$geodocs-visualisation--frame-tab-bar-button--active--background-color: $geodocs--white;
$geodocs-visualisation--frame-tab-bar-button--active--color: $aurecon--grey--600;
$geodocs-visualisation--frame-tab-bar-drag-button--background-color: $aurecon--grey--700;
$geodocs-visualisation--frame-tab-bar-drag-button--hover--background-color: $geodocs--white;
$geodocs-visualisation--frame-tab-bar-drag-button--hover--color: $aurecon--grey--600;
$geodocs-visualisation--frame-tab-bar-expand-collapse-button--background-color: $aurecon--grey--700;
$geodocs-visualisation--frame-tab-bar-expand-collapse-button--hover--background-color: $geodocs--white;
$geodocs-visualisation--frame-tab-bar-expand-collapse-button--hover--color: $aurecon--grey--600;

// src\components\visualisation\widgets\styles\LegendWidget.module.scss

$geodocs--visualisation--legend-widget--expand--background-color: $geodocs--ascent-light;

// src\components\visualisation\widgets\styles\ZoomWidget.module.scss

$geodocs--visualisation--zoom-widget--background-color: transparent;

// src\components\projectSettings\styles\ProjectCard.module.scss

$geodocs--project-settings--project-card--border-color: #d4d4d4;

// src/components/projectUserManagement/appointingParty/styles/AppointingParty.module.scss
$geodocs--project-user-management--appointing-party--button--border-color: $geodocs--black;
$geodocs--project-user-management--appointing-party--button--background-color: #424242;
$geodocs--project-user-management--appointing-party--sub-page-header--color: #757575;

// src\components\projectUserManagement\appointingParty\styles\AddAppointingPartyUserModal.module.scss
$geodocs--project-user-management--appointing-party-user-modal--user-selector--pill-wrapper--background-color: #424242;
$geodocs--project-user-management--appointing-party-user-modal--user-selector--pill-wrapper--color: $geodocs--white;
$geodocs--project-user-management--appointing-party-user-modal--part-role-checkbox--border-color: #424242;
$geodocs--project-user-management--appointing-party-user-modal--part-role-checkbox-is-checked--background-color: #212121;
$geodocs--project-user-management--appointing-party-user-modal--part-role-checkbox-is-checked--color: $geodocs--white;

// src/components/projectUserManagement/appointedParties/styles/AppointedParties.module.scss
$geodocs--project-user-management--appointed-parties--sub-page-header--color: #757575;
$geodocs--project-user-management--appointed-parties--button-text--color: #424242;
$geodocs--project-user-management--appointed-parties--button-text--hover--background-color: transparent;
$geodocs--project-user-management--appointed-parties--button-text--focus--background-color: transparent;
$geodocs--project-user-management--appointed-parties--button-text--focus--border-color: transparent;
$geodocs--project-user-management--appointed-parties--no-teams-text--color: #121212;

// src/components/projectUserManagement/appointedParties/styles/AppointedPartiesUserTable.module.scss
$geodocs--project-user-management--appointed-parties--user-table--add-task-team-member--color: #424242;
$geodocs--project-user-management--appointed-parties--user-table--add-task-team-member--background-color: #f2f2f2;
$geodocs--project-user-management--appointed-parties--user-table--add-task-team-member--border-color: #f2f2f2;
$geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--color: #f2f2f2;
$geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--hover--color: #424242;
$geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--active--color: #424242;
$geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--focus--color: #424242;
$geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--background-color: #424242;
$geodocs--project-user-management--appointed-parties--user-table--add-delivery-team-member--border-color: #424242;
$geodocs--project-user-management--appointed-parties--user-table--tooltip--color: #121212;

// src\components\files\styles\DeleteOverflowButton.module.scss
$geodocs--delete-overflow-button--button--border-color: $geodocs--button--border-color;
$geodocs--delete-overflow-button--button--background-color: $geodocs--white;
$geodocs--delete-overflow-button--button--disabled--background-color: rgba(255, 255, 255, 0.5);
$geodocs--delete-overflow-button--dropdown--background-color: #fafafa;
$geodocs--delete-overflow-button--dropdown--hover--background-color: $geodocs--ascent;
$geodocs--delete-overflow-button--dropdown--hover--color: $geodocs--black;

// src\components\login\styles\SupportHub.module.scss
$geodocs--login--support-hub--hover-color: $geodocs--ascent;

// src\components\projectUserManagement\appointedParties\teamSettings\styles\TeamSettings.module.scss
$geodocs--team-settings--add-delivery-team-button--color: #424242;
$geodocs--team-settings--add-delivery-team-button--background-color: #f2f2f2;
$geodocs--team-settings--add-delivery-team-button--border-color: #f2f2f2;
$geodocs--team-settings--delivery-team--color: #424242;
$geodocs--team-settings--delivery-team--background-color: #f2f2f2;
$geodocs--team-settings--delivery-team--add-button--color: #424242;
$geodocs--team-settings--delivery-team--add-button--background-color: #f2f2f2;
$geodocs--team-settings--delivery-team--add-button--border-color: #f2f2f2;
$geodocs--team-settings--delivery-team--add-button--hover--color: $geodocs--black;
$geodocs--team-settings--delivery-team--add-button--hover--background-color: #f37021;
$geodocs--team-settings--delivery-team--add-button--hover--border-color: #f37021;
$geodocs--team-settings--task-team--border-color: #f2f2f2;

// src\components\projectUserManagement\appointedParties\teamSettings\styles\TeamSettingsInput.module.scss
$geodocs--team-settings-input--name--border-bottom-color: #424242;
$geodocs--team-settings-input--transmittalCode--border-bottom-color: #424242;
$geodocs--team-settings-input--add--background-color: transparent;
$geodocs--team-settings-input--add--color: #424242;
$geodocs--team-settings-input--add--disabled--background-color: transparent;
$geodocs--team-settings-input--add--hover--background-color: #fdebdf;
$geodocs--team-settings-input--add--hover--color: #424242;

// src\components\shared\styles\UserSelector.module.scss
$geodocs--user-selector--required--color: #e0452a;

// src\components\projectUserManagement\appointedParties\styles\AddTaskTeamMember.module.scss
$geodocs--add-task-team-member--approver--border-color: #212121;
$geodocs--add-task-team-member--approver--checked--background-color: #212121;
$geodocs--team-settings-input--add--validation--error--color: $geodocs--task--rejected;
$geodocs--team-settings-input--add--validation--valid--color: $geodocs--task--approved;

// src\components\shared\styles\Dot.module.scss
$geodocs--dot--background-color: #424242;

// src\components\transmittalDetails\styles\TransmittalDetails.module.scss
$geodocs--transmittal-details--panel--border-color: #f2f2f2;
$geodocs--transmittal-details--sub-heading--background-color: #f2f2f2;
$geodocs--transmittal-details--flagged--color: #da3b20;
$geodocs--transmittal-details--overdue--background-color: #da3b20;

// src\components\transmittalDetails\styles\TransmittalResponseCard.module.scss
$geodocs--transmittal-response-card--card--background-color: #f2f2f2;

// src\components\shared\styles\SeeMoreLink.module.scss
$geodocs--see-more-link--color: #666666;

// src\ClientApp\src\components\transmittals\styles\TransmittalTabContent.module.scss
$geodocs--transmittals-tab-container-progress--overlay--background-color: rgb(248, 248, 248);
$geodocs--transmittals-tab-container-progress--overlay--spinner-z-index: 1001;

// src\ClientApp\src\components\transmittals\styles\TransmittalItem.module.scss
$geodocs--transmittals-item--label--color: #000000;
$geodocs--transmittals-item--message-subject--color: #000000;
$geodocs--transmittals-item--overdue--background-color: #da3b20;
$geodocs--transmittals-item--flagged--background-color: #da3b20;
$geodocs--transmittals-item--closed--background-color: #9e9e9e;
$geodocs--transmittals-item--item-detail-wrapper--background-color: #fafafa;
$geodocs--transmittals-item--info--icon--colour: #000000;

// src\ClientApp\src\components\transmittals\styles\TransmittalNotifiers.module.scss
$geodocs--transmittals-notifier--user-name--color: #000000;
$geodocs--transmittals-notifier--task-team--color: #666666;

// src\ClientApp\src\components\transmittals\styles\TransmittalInitiator.module.scss
$geodocs--transmittals-initiator--user-name--color: #000000;
$geodocs--transmittals-initiator--team-icon--color: #000000;

// src\components\transmittals\styles\TransmittalActionBar.module.scss
$geodocs--transmittals-action-bar--filter-label--color: #121212;
// src\components\transmittal\styles\TransmittalTabContainerActionBar.module.scss
$geodocs--transmittal-actionbar--filter--background-color: #fdebdf;
$geodocs--transmittal-filter--color: #ffffff;
$geodocs--transmittal-filter--border-color: #212121;
$geodocs--transmittal-filter--background-color: #212121;

// src\components\transmittalDetails\styles\TransmittalNotifiersModal.module.scss
$geodocs--transmittal-notifiers-modal--count-circle--background-color: #fafafa;
$geodocs--transmittal-notifiers-modal--count-circle--color: #000000;

// src\components\transmittalDetails\styles\TransmittalVisibleModal.module.scss
$geodocs--transmittal-visible-modal--count-circle--background-color: #fafafa;
$geodocs--transmittal-visible-modal--count-circle--color: #000000;

// src\components\transmittals\styles\TransmittalTabContainerActionBar.module.scss
$geodocs--transmittal--action-bar--export-btn--border-color: #424242;
$geodocs--transmittal--action-bar--export-btn--background-color: #fff;
$geodocs--transmittal--action-bar--export-btn--color: #424242;

$geodocs--transmittal--action-bar--export-btn--hover-color: #fff;
$geodocs--transmittal--action-bar--export-btn--hover-border-color: #f37021;
$geodocs--transmittal--action-bar--export-btn--hover-background-color: #f37021;

$geodocs--transmittal--action-bar--export-btn--disabled-color: #757575;
$geodocs--transmittal--action-bar--export-btn--disabled-border-color: #eeeeee;
$geodocs--transmittal--action-bar--export-btn--disabled-background-color: #eeeeee;

$geodocs--transmittal--action-bar--sort-btn--background-color: #fff;
$geodocs--transmittal--action-bar--sort-btn--border-color: #000000;

// src\components\transmittalDetails\AddTransmittalMessage\styles\AddTransmittalMessage.module.scss
$geodocs--add-transmittal-response--required-symbol--color: $geodocs--warning;
$geodocs--add-transmittal-response--see-more-link--color: #000000;

// src\components\transmittalDetails\AddTransmittalMessage\styles\AddTransmittalMessageActions.module.scss
$geodocs--add-transmittal-response--add-button--color--focus: $geodocs--white;
$geodocs--add-transmittal-response--add-button--background-color--focus: $geodocs--black;
$geodocs--add-transmittal-response--add-button--color--hover: $geodocs--black;
$geodocs--add-transmittal-response--add-button--background-color--hover: $geodocs--ascent;
$geodocs--add-transmittal-response--add-button--border-color--hover: $geodocs--ascent;

// src\components\transmittalDetails\AddTransmittalMessage\styles\AddTransmittalMessageVisibilitySelector.module.scss
$geodocs--add-transmittal-message-visibility-selector--user-pill--background-color: #424242 !important;
$geodocs--add-transmittal-message-visibility-selector--user-pill--color: #fff !important;

// src\components\transmittalDetails\AddTransmittalMessage\styles\AddTransmittalMessageNotifySelector.module.scss
$geodocs--add-transmittal-message-notify-selector--user-pill--background-color: #424242 !important;

$geodocs--add-transmittal-message-notify-selector--user-pill--color: #fff !important;
$geodocs--add-transmittal-message-notify-selector--disable--color: rgb(0, 0, 0, 0.5) !important;

$geodocs--transmittal-response-card--message--color: #666666;
$geodocs--transmittal-response-card-focus--border: 1px solid $geodocs--ascent;

// src\components\transmittals\styles\CreateTransmittal.module.scss
$geodocs--transmittal--create--date-button--hover--border-color: #f37021;
$geodocs--transmittal--create--date-button--hover--background-color: #fff;
$geodocs--transmittal--create--date-calendar-button-icon--color: #f37021;

// src\components\transmittals\styles\CreateTransmittalActions.module.scss
$geodocs--transmittals-create-transmittal-actions--dropdown-menu--color: #424242;
$geodocs--transmittals-create-transmittal-actions--dropdown-menu--border-color: #424242;
$geodocs--transmittals-create-transmittal-actions--dropdown-menu--background-color: #fff;

// src\ClientApp\src\components\transmittalDetails\TransmittalDetailsFiles.tsx
$geodocs--transmittals-details-filedetails-downloadallbutton: $geodocs--black;

// src\ClientApp\src\components\shared\styles\ThreeUserCircles.module.scss
$geodocs--three-user-circles--pill--background-color: #424242 !important;
$geodocs--three-user-circles--pill--color: #fff !important;

// src\components\content\wipTab\upload\styles\SupersedeModal.module.scss
$geodocs--file-name-wrapper--background-color: #f2f2f2;
$geodocs--locked-content-color: #9e9e9e;

// src\ClientApp\src\components\ProjectTeamSelector\styles\ProjectTeamSelector.module.scss
$geodocs--project-team-user-selector--panel-title-color: #000000;
$geodocs--project-team-user-selector--hr-color: #d4d4d4;
$geodocs--project-team-user-selector--selected-users-label-color: #121212;
$geodocs--project-team-user-selector--task-team-wrapper--group-tile-item--color: #bbbbbb;
$geodocs--project-team-user-selector--task-team-wrapper--task-team-item--hover--background-color: #fdebdf;
$geodocs--project-team-user-selector--task-team-wrapper--task-team-item--active--color: #c2185b;
$geodocs--project-team-user-selector--task-team-wrapper--delivery-team-role-title--color: #337bb7;
$geodocs--project-team-user-selector--task-team-wrapper--external-team-role-title--color: #00796B;
