@import '../../../styles/Variables';

.subHeading {
  background-color: $geodocs--transmittal-details--sub-heading--background-color;
  display: flex;
  align-items: center;
  padding: 0 24px;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    padding: 5px 0;
    margin: 0;
  }
}

.actionBar {
  border-bottom-style: solid;
  border-top-style: solid;
}

.flagged {
  font-size: 18px;
  color: $geodocs--transmittal-details--flagged--color;
  margin-left: 14px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  font-size: 14px;

  h1 {
    display: inline-block;
  }
}

.panel {
  margin-top: 20px;
  padding: 0 40px;
  font-size: 14px;
  overflow-y: auto;
  margin-bottom: 60px;

  hr {
    border-top: 1px solid $geodocs--transmittal-details--panel--border-color;
    margin-top: 36px;
    margin-bottom: 12px;
  }
}

.labelIcon {
  font-size: 16px;
  margin-right: 18px;
}

.teamIcon {
  font-size: 16px;
  margin: 0 12px;
}

.initiatorName {
  margin-left: 8px;
}

$label-width: 200px;
$see-more-link-width: 200px;

.row,
.rowDisplay {
  display: flex;
  font-size: 14px;
  margin-bottom: 5px;
}

.rowDisplay {
  margin: 12px 0;
}

.row > div:nth-child(2) {
  flex: 1;
}
.rowDisplay > div:nth-child(4) {
  flex: 1;
}

.label,
.userSelectorLabel {
  font-weight: bold;
  width: $label-width;
}

.label {
  margin-top: 10px;
}

.userSelectorLabel {
  margin-top: 20px;
}

.textEditor,
.formInput,
.userSelector,
.threeUsersCircle,
.displayValue {
  display: inline-block;
}

.textEditor,
.formInput {
  width: calc(100% - $label-width);
}

.displayValue {
  width: calc((100% - 400px) / 2);
  margin-top: 10px;
}

.userSelector {
  width: calc(100% - $label-width - $see-more-link-width);
}

.seeMoreLink {
  width: $see-more-link-width;
  text-decoration: underline;
  margin-top: 15px;
}

.requiredSymbol {
  color: $geodocs--add-transmittal-response--required-symbol--color;
}

.date {
  button:global(.react-date-picker__calendar-button) {
    &:hover {
      background-color: $geodocs--transmittal--create--date-button--hover--background-color;
      border-bottom: 1px solid $geodocs--transmittal--create--date-button--hover--border-color;
    }
    i {
      color: $geodocs--transmittal--create--date-calendar-button-icon--color;
    }
  }

  button:global(.react-date-picker__clear-button) {
    &:hover {
      background-color: $geodocs--transmittal--create--date-button--hover--background-color;
      border-bottom: 1px solid $geodocs--transmittal--create--date-button--hover--border-color;
    }
  }
}

.teamChartBtnWrapper {
  margin-left: 2rem;
  display: flex;
  .teamChartBtn {
    margin-top: auto;
  }
}
